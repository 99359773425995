<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import { getDicts } from "@/api/system/dict/data";
import TablePage from "@/components/tablePage";
import { listProduceGroupGoods } from "@/api/produce/sets/produceGroup"; //生产组、生产组商品详情信息
export default {
  name: "ProduceGroupGoods",
  dicts: ["goods_type"],
  components: { TablePage },
  data() {
    return {
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        uuid:'82a30440-eed2-aa23-6a30-cbf9779fe0d1',
        body: {},
        getListApi: listProduceGroupGoods,
        exportOption: {
          fastExportUrl:
            "/api/system/produce/bas/group/goods/exportProduceGroupGoods",
          exportName: "生产组商品查询",
        },
        title: "生产组商品查询",
        search: [
          this.$select({
            key: "listGoods",
            option: {
              filter: "goodsIds",
              option: {
                multiple: true,
                remoteBody: {
                  goodsTypes: [1, 2],
                },
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({
                        key: 'goods',
                        option: {
                          table: {
                            ...this.$dialog({ key: 'goods' }).table,
                            pagination: {
                              ...this.$dialog({ key: 'goods' }).table.pagination,
                              goodsTypes: [1, 2],
                            }
                          },
                        },
                      }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listCategory",
            option: {
              filter: "goodsCategoryIds",
              option: {
                multiple: true,
                buttons: [
                  {
                    type: "more",
                    option: {
                      title: "选择商品类别",
                      width: 1250,
                      type: "TreeAndTable",
                      formData: this.$dialog({ key: "category" }),
                    },
                  },
                ],
              },
            },
          }),
          this.$select({
            key: "listProduceGroup",
            option: {
              filter: "produceGroupIds",
              option: {
                clearable: true,
                multiple: true,
              },
            },
          }),
          {
            type: "local",
            label: "商品类型",
            model: "",
            filter: "goodsTypes",
            option: {
              multiple: true,
              clearable: true,
              data: [
                { label: "成品 ", value: 1 },
                { label: "半成品", value: 2 },
              ],
              label: "label",
              value: "value",
              filterable: true,
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        columns: [
          { label: "商品编码", prop: "goodsNo", minWidth: 180 },
          { label: "商品名称", prop: "goodsName", minWidth: 160 },
          { label: "条码", prop: "barcode", minWidth: 160 },
          { label: "规格", prop: "goodsSpec", minWidth: 100 },
          { label: "单位", prop: "unitName", minWidth: 100 },
          { label: "商品类别", prop: "categoryName", minWidth: 100 },
          {
            prop: "goodsType",
            type: "dicts",
            name: "goods_type",
            label: "商品类型",
            minWidth: 100,
          },
          { label: "生产组编号", prop: "produceGroupNo", minWidth: 100 },
          { label: "生产组名称", prop: "produceGroupName", minWidth: 100 },
        ],
        summary: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "oepnDetail": //获取点击商品信息
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
