<template>
  <div>
    <TablePage ref="tablePage" class="tablepage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="450" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form :model="form" :rules="rules" ref="ruleForm" v-loading="dialogLoading" label-width="120px">
          <el-form-item label="生产工厂" prop="produceDeptId">
            <SelectRemote v-model="form.produceDeptId" style="width: 250px" :option="
                $select({
                  key: 'listProduceDept'
                }).option
              " />
          </el-form-item>
          <el-form-item label="生产仓库编号" prop="produceStoreNo">
            <SelectRemote @selectChange="selectChangeGoods" v-model="form.storeId" style="width: 250px" :option="
                $select({
                  key: 'listStore',
                  option: {
                    option: {
                    remoteBody: { storeTypes: [0] },
                      label: 'storeNo'
                    }
                  }
                }).option
              " />
          </el-form-item>
          <el-form-item label="生产仓库名称" prop="produceStoreName">
            <el-input style="width: 250px" size="mini" disabled v-model="form.produceStoreName" autocomplete="off"
              placeholder="请输入生产仓库名称"></el-input>
          </el-form-item>
          <el-form-item label="生产仓库属性" prop="storeType">
            <el-select class="inputWidth" v-model="form.storeType" placeholder="请选择生产仓库属性">
              <el-option label="成品仓" :value="1"></el-option>
              <el-option label="半品仓" :value="2"></el-option>
              <el-option label="原料仓" :value="3"></el-option>
              <el-option label="配料间" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 批量编辑对话框 -->
    <c-dialog :title="titleBatch" :width="450" :showDialog.sync="dialogFormVisibleBatch">
      <template #content>
        <el-form :model="formBatch" label-width="120px">
          <el-form-item label="生产工厂" prop="produceDeptId">
            <SelectRemote v-model="formBatch.produceDeptId" style="width: 250px" :option="
                $select({
                  key: 'listProduceDept'
                }).option
              " />
          </el-form-item>
          <el-form-item label="生产仓库属性" prop="storeType">
            <el-select class="inputWidth" v-model="formBatch.storeType" placeholder="请选择生产仓库属性">
              <el-option label="成品仓" :value="1"></el-option>
              <el-option label="半品仓" :value="2"></el-option>
              <el-option label="原料仓" :value="3"></el-option>
              <el-option label="配料间" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="dialogFormVisibleBatch = false">取 消</el-button>
        <el-button type="primary" @click="submitFormBatch">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage'
import { queryProduceBasDeptList } from '@/api/produce/sets/produceDept' //生产工厂
import CDialog from '@/components/dialogTemplate/dialog/index.vue' //弹窗
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import {
  produceStoreList,
  produceStoreAdd,
  produceStoreUpdate,
  produceStoreRemove,
  produceStoreDetail,
  updateBatchStoreInfo
} from '@/api/produce/sets/produceDeptStore' //生产仓库
export default {
  name: 'produceDeptStore',
  components: {
    TablePage,
    CDialog,
    SelectRemote
  },
  data() {
    return {
      titleBatch: '批量修改',
      dialogFormVisibleBatch: false, //批量修改对话框
      dialogFormVisible: false, //新增、单独修改对话框
      dialogLoading: false, //表单
      title: '生产仓库',
      //对话框表单
      formBatch: {
        produceDeptId: undefined,
        storeType: undefined
      },
      //对话框表单
      form: {
        produceDeptId: undefined,
        produceStoreNo: undefined,
        produceStoreName: undefined,
        storeType: undefined,
        remark: undefined
      },
      //新增表单校验
      rules: {
        lineId: [
          {
            required: true,
            message: '生产仓库编号不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      options: {
        mutiSelect: true, // 多选
        selectable: row => row.produceStoreNo != '0000',
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        uuid:'7fa47d4d-ffd7-9dac-4866-cdd1af78fdf1',
        tree: {
          title: '生产工厂',
          getListApi: queryProduceBasDeptList,
          label: 'produceDeptName',
          value: 'produceDeptId'
        },
        getListApi: produceStoreList,
        body: {},
        defaultBody: {},
        title: '生产仓库',
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'lineId',
        //搜索
        search: [
          {
            type: 'filters',
            tip: '生产仓库编号/生产仓库名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'produceStoreNos', label: '生产仓库编号' },
              { filter: 'produceStoreNames', label: '生产仓库名称' }
            ]
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'batchEdit',
            label: '批量编辑',
            icon: 'el-icon-edit-outline',
            type: 'primary',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            disabled: () => !this.options?.check?.length
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'produceStoreNo',
            label: '生产仓库编号',
            type: 'link',
            click: 'update',
            minWidth: 160,
            align: 'center',
            fixed: true
          },
          {
            prop: 'produceStoreName',
            label: '生产仓库名称',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'storeTypeName',
            label: '仓库属性',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'produceDeptName',
            label: '生产工厂',
            minWidth: 160,
            align: 'center'
          }
        ],
        list: []
      }
    }
  },
  methods: {
    // 删除按钮
    async handleDelete() {
      this.$confirm('您确认要删除选中的生产仓库吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await produceStoreRemove(this.options.check.map(x => x.lineId))
          await this.$refs.tablePage.getList()
          this.$message.success('删除成功！')
        })
        .catch(() => { })
    },
    //切换
    selectChangeGoods(val, value) {
      this.$set(this.form, 'produceStoreNo', value.storeNo)
      this.$set(this.form, 'produceStoreName', value.storeName)
    },
    async handleEvent(type, row) {
      switch (type) {
        case 'add':
          this.title = '新增仓库'
          this.dialogFormVisible = true
          this.dialogLoading = true
          this.reset()
          this.form.produceDeptId = this.options?.tree?.body?.produceDeptId
          this.dialogLoading = false
          break
        case 'update':
          this.title = '编辑生产工厂'
          this.dialogFormVisible = true
          this.dialogLoading = true
          this.reset()
          const res = await produceStoreDetail(row.lineId)
          this.form = res.data
          this.dialogLoading = false
          break
        case 'batchEdit':
          this.formBatch = {
            produceDeptId: undefined,
            storeType: undefined
          }
          this.dialogFormVisibleBatch = true
          break
        case 'del':
          this.handleDelete()
          break
        default:
          break
      }
    },
    submitForm() {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.form.tenantId) {
            await produceStoreUpdate(this.form)
            this.cancel()
            await this.$refs.tablePage.getList()
            this.$modal.msgSuccess('修改生产工厂成功')
          } else {
            await produceStoreAdd(this.form)
            this.cancel()
            await this.$refs.tablePage.getList()
            this.$modal.msgSuccess('新增生产工厂成功')
          }
        }
      })
    },
    //取消
    cancel() {
      this.reset()
      this.dialogFormVisible = false
    },

    //批量修改弹窗的确认
    async submitFormBatch() {
      if (this.formBatch.produceDeptId || this.formBatch.storeType) {
        await updateBatchStoreInfo({
          ...this.formBatch,
          lineIds: this.options.check.map(x => x.lineId)
        })
        this.dialogFormVisibleBatch = false
        await this.$refs.tablePage.getList()
        this.$message.success('批量修改成功')
      } else {
        this.$message.error('批量修改内容不能为空')
      }
    },
    // 表单重置
    reset() {
      this.form = {
        produceDeptId: undefined,
        produceStoreNo: undefined,
        produceStoreName: undefined,
        storeType: undefined,
        remark: undefined
      }
      this.resetForm('ruleForm')
    }
  }
}
</script>

<style lang="scss" scoped></style>
