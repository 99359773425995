<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent">
    </TablePage>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="450" :showDialog.sync="dialogFormVisible">
      <template #content>
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          v-loading="dialogLoading"
          label-width="120px"
        >
          <el-form-item label="生产工厂编号" prop="produceDeptNo">
            <el-input
              style="width: 250px"
              size="mini"
              v-model="form.produceDeptNo"
              autocomplete="off"
              placeholder="请输入生产工厂编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="生产工厂名称" prop="produceDeptId">
            <SelectLocal
              @selectChange="selectChangeGoods"
              v-model="form.produceDeptId"
              style="width: 250px"
              :option="{
                data: deptList,
                labels: [
                  { title: '编号', label: 'deptNo' },
                  { title: '名称', label: 'deptName' }
                ],
                value: 'deptId',
                label: 'deptName'
              }"
            />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              size="mini"
              v-model="form.remark"
              autocomplete="off"
              type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间"
              maxlength="80"
              :autosize="{ minRows: 2, maxRows: 4 }"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import TablePage from '@/components/tablePage' //表格页面组件
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import { listSimpleQuery } from '@/api/system/dept' //部门
import {
  produceDeptList,
  produceDeptAdd,
  produceDeptUpdate,
  produceDeptRemove,
  produceDeptDetail,
  produceDeptRestore
} from '@/api/produce/sets/produceDept' //生产工厂
export default {
  name: 'produceDept',
  dicts: ['common_status'],
  components: { TablePage, CDialog, SelectLocal },
  data () {
    return {
      deptList: [], //部门数组
      //新增表单校验
      rules: {
        produceDeptNo: [
          {
            required: true,
            message: '生产工厂编号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        produceDeptId: [
          {
            required: true,
            message: '生产工厂名称不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      dialogFormVisible: false, //新增、单独修改对话框
      dialogLoading: false, //表单
      title: '生产工厂',
      //对话框表单
      form: {
        produceDeptNo: undefined,
        produceDeptName: undefined,
        remark: undefined
      },
      options: {
        mutiSelect: true, // 多选
        selectable: row => row.produceDeptNo != '0000',
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        uuid:'03186340-768e-69c4-c16e-42956d487c3c',
        title: '生产工厂',
        getListApi: produceDeptList,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: 'produceDeptId',
        //搜索
        search: [
          {
            type: 'filters',
            tip: '生产工厂编号/生产工厂名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'produceDeptNos', label: '生产工厂编号' },
              { filter: 'produceDeptNames', label: '生产工厂名称' }
            ]
          },
          {
            type: 'button',
            tip: '查询',
            btnType: 'primary',
            click: 'search',
            isRestore: true
          }
        ],
        //按钮
        buttons: [
          {
            click: 'add',
            label: '新增',
            icon: 'el-icon-plus',
            type: 'primary'
          },
          {
            click: 'del',
            label: '删除',
            type: 'danger',
            icon: 'el-icon-delete',
            alertText: '确认要删除选中生成工厂吗？',
            btnType: 'dropdown',
            trigger: 'click', // 要求
            other: [
              {
                label: '回收站',
                collectionDialog: {
                  restoreListApi: produceDeptRestore,
                  dcollectionBody: {
                    delFlag: '1'
                  }
                }
              }
            ]
          },
          {
            click: 'refresh',
            right: true,
            label: '刷新',
            icon: 'el-icon-refresh',
            type: ''
          }
        ],
        //表格
        columns: [
          {
            prop: 'produceDeptNo',
            label: '生产工厂编号',
            type: 'link',
            click: 'update',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'produceDeptName',
            label: '生产工厂名称',
            minWidth: 150,
            align: 'center'
          },
          {
            prop: 'remark',
            label: '备注',
            minWidth: 110,
            align: 'center'
          },
          {
            prop: 'updateBy',
            label: '修改人',
            minWidth: 110,
            align: 'center'
          },
          {
            prop: 'updateTime',
            label: '修改时间',
            minWidth: 160,
            align: 'center'
          },
          {
            prop: 'createBy',
            label: '创建人',
            minWidth: 110,
            align: 'center'
          },
          {
            prop: 'createTime',
            label: '创建时间',
            minWidth: 160,
            align: 'center'
          }
        ],
        list: []
      },
      delFlag: null, //删除标志
      normal: 0, //状态正常
      disable: 1, //状态停用
      total: 0,
      loading: false, //表格加载
      multiple: true, //删除
      //   右边表格
      tableData: [],
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        vipLevelName: null
      }
    }
  },
  async created () {
    //部门
    const res = await listSimpleQuery()
    this.deptList = res.data
  },
  methods: {
    //切换
    selectChangeGoods (val, value) {
      this.$set(this.form, 'produceDeptName', value.deptName)
    },
    async handleEvent (type, row) {
      switch (type) {
        case 'add':
          this.title = '新增生产工厂'
          this.dialogFormVisible = true
          this.dialogLoading = true
          this.reset()
          this.dialogLoading = false
          break
        case 'update':
          if (row.produceDeptNo != '0000') {
            this.title = '编辑生产工厂'
            this.dialogFormVisible = true
            this.dialogLoading = true
            this.reset()
            const res = await produceDeptDetail(row.produceDeptId)
            this.form = res.data
            this.dialogLoading = false
          }

          break
        case 'del':
          this.handleDelete()
          break
        default:
          break
      }
    },
    submitForm () {
      this.$refs['ruleForm'].validate(async valid => {
        if (valid) {
          if (this.form.tenantId) {
            await produceDeptUpdate(this.form)
            this.cancel()
            this.$refs.tablePage.getList()
            this.$modal.msgSuccess('修改生产工厂成功')
          } else {
            await produceDeptAdd(this.form)
            this.cancel()
            this.$refs.tablePage.getList()
            this.$modal.msgSuccess('新增生产工厂成功')
          }
        }
      })
    },
    //取消
    cancel () {
      this.reset()
      this.dialogFormVisible = false
    },
    // 表单重置
    reset () {
      this.form = {}
      this.resetForm('ruleForm')
    },
    // 删除按钮
    async handleDelete () {
      this.$confirm('您确认要删除选中的生产工厂吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await produceDeptRemove(this.options.check.map(x => x.produceDeptId))
          this.$refs.tablePage.getList()
          this.$message.success('删除成功！')
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
//输入框
.vipInput {
  width: 89%;
}
.searchInput {
  width: 68%;
  margin-right: 2%;
}
//整体盒子

.tableBody {
  padding: 30px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

//顶部操作
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
